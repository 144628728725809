import React from "react";
import Grid from "@mui/material/Grid";
import styles from "./UserPost.module.css";
import { format } from "date-fns";

const UserPost = ({ title, userName, userAvatar, content, datePosted }) => {
    return (
        <Grid
            container
            className={`${styles.noMargin} ${styles.blackThinBorder} ${styles.postWidth} ${styles.center} ${styles.minorPadding} ${styles.whiteBackground}`}
        >
            <Grid
                item
                xl={9}
                className={`${styles.noMargin} ${styles.blackThinBorder}`}
            >
                <h2>{title}</h2>
            </Grid>
            <Grid
                item
                xl={3}
                className={`${styles.noMargin} ${styles.blackThinBorder}`}
            >
                <img
                    alt={`Avatar of ${userName}`}
                    src={userAvatar}
                    className={`${styles.avatar} ${styles.minorPadding}`}
                />
                <h4>{userName}</h4>
            </Grid>
            <Grid
                item
                xl={12}
                className={`${styles.noMargin} ${styles.blackThinBorder}`}
            >
                <p>{content}</p>
            </Grid>
            <Grid
                item
                xl={12}
                className={`${styles.noMargin} ${styles.blackThinBorder} ${styles.date}`}
            >
                <p className={`${styles.minorPadding}`}>
                    {format(new Date(datePosted), "PPpp")}
                </p>
            </Grid>
        </Grid>
    );
};

export default UserPost;
