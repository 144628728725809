import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";

//this involves a lot of boiler plate, so I used this resource (https://onestepcode.com/creating-a-material-ui-form/) and adapted the form thereon to fit mine own needs

const AddNewPostForm = ({ onSubmitHandler }) => {
    const [formValues, setFormValues] = useState({
        title: "",
        userName: "",
        userAvatar: "",
        content: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmitHandler(formValues);
    };
    return (
        <form onSubmit={handleSubmit}>
            <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
            >
                <Grid item>
                    <TextField
                        id="title-input"
                        name="title"
                        label="Title"
                        type="text"
                        value={formValues.title}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="userName-input"
                        name="userName"
                        label="UserName"
                        type="text"
                        value={formValues.userName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="userAvatar-input"
                        name="userAvatar"
                        label="Link to User Avatar"
                        type="text"
                        value={formValues.userAvatar}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="content-input"
                        name="content"
                        label="Post Content"
                        type="text"
                        value={formValues.content}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Button variant="contained" color="primary" type="submit">
                    Submit
                </Button>
            </Grid>
        </form>
    );
};
export default AddNewPostForm;
