import "./App.css";
import React, { useState, useEffect } from "react";
import Posts from "./components/Posts/Posts";
import Button from "@mui/material/Button";
import AddNewPostForm from "./components/AddNewPostForm/AddNewPostForm";
import { styled } from "@mui/system";
import { StyledEngineProvider } from "@mui/material/styles";
import axios from "axios";
import { POSTS_URL } from "./URLRoutes";
import { format, isBefore } from "date-fns";

const CustomButton = styled(Button)({
    background:
        "linear-gradient(90deg, rgba(128,128,128,1) 0%, rgba(0,0,0,1) 25%, rgba(0,0,0,1) 75%, rgba(128,128,128,1) 100%)",
    border: 0,
    borderRadius: 3,
    color: "white",
    width: "90%",
    fontSize: "2em",
    padding: "10px 30px",
    margin: "20px auto",
});

function App() {
    const [isPendingNewPost, setIsPendingNewPost] = useState(false);
    //will be used to store the fetched posts
    const [postFetch, setPostFetch] = useState([]);

    //get all the posts data and store it into state so that it may then be displayed
    useEffect(() => {
        console.log("RERENDERING");
        axios
            .request(POSTS_URL, {
                headers: { "Access-Control-Allow-Origin": "*" },
            })
            .then((response) => {
                //sort in descending time, as in most recent post first
                const orderedPosts = response.data.sort((prev, next) =>
                    isBefore(
                        new Date(prev.datePosted),
                        new Date(next.datePosted)
                    )
                );
                setPostFetch(() => orderedPosts);
                console.log(
                    orderedPosts.map((post) =>
                        format(new Date(post.datePosted), "d/M/y")
                    )
                );
            })
            .catch((err) => {
                console.log(postFetch);
                console.log("DATA NOT RECEIVED");
                console.log(err);
            });
    }, [isPendingNewPost]);

    const addNewPostButtonClickHandler = (event) => {
        setIsPendingNewPost(() => true);
    };

    const additionOfNewPostHandler = (newPostData) => {
        const NewPost = { ...newPostData, datePosted: new Date() };
        console.log("SEEKING POST OF ");
        console.log(NewPost);
        axios
            .post(
                POSTS_URL,
                { ...NewPost },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((response) => {
                console.log("A NewPost has been posted.");
                setIsPendingNewPost(() => false);
            })
            .catch((err) => {
                console.log("A NewPost has not been posted. Try again");
                console.log(err);
            });
    };

    return (
        <div className="App">
            <StyledEngineProvider injectFirst>
                {isPendingNewPost ? (
                    <AddNewPostForm
                        onSubmitHandler={additionOfNewPostHandler}
                    ></AddNewPostForm>
                ) : (
                    <>
                        <CustomButton
                            variant="contained"
                            onClick={addNewPostButtonClickHandler}
                        >
                            Make a New Post
                        </CustomButton>
                        <Posts postFetch={postFetch}></Posts>
                    </>
                )}
                {/* {isPendingNewPost ? } */}
            </StyledEngineProvider>
        </div>
    );
}

export default App;
