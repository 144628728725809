import React from "react";
import styles from "./Posts.module.css";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import UserPost from "../UserPost/UserPost";
import axios from "axios";
import { POSTS_URL } from "../../URLRoutes";
import { styled } from "@mui/material/styles";

//From the Material UI-Documentation
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
}));

const Posts = (props) => {
    return (
        <Stack
            direction="column"
            className={`${styles.limitedWidth} ${styles.center}`}
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={2}
        >
            {props.postFetch.map((fetchedPost) => {
                return (
                    <Item>
                        <UserPost
                            key={fetchedPost.id}
                            title={fetchedPost.title}
                            userName={fetchedPost.userName}
                            userAvatar={fetchedPost.userAvatar}
                            content={fetchedPost.content}
                            datePosted={fetchedPost.datePosted}
                        ></UserPost>
                    </Item>
                );
            })}
        </Stack>
    );
};

export default Posts;
